<div class="d-flex flex-column content">

  <div *ngIf="isLoading">
    <div class="p-2 align-self-center">
      <mat-spinner [diameter]="60" class="mat-spinner-color"></mat-spinner>
    </div>
  </div>

  <div *ngIf="!isLoading">

    <div class="p-2 align-self-center">
      <h3>{{ 'user.description' | translate}}</h3>
    </div>

    <div class="p-2">
      <label class="grey-18">{{'case.number' | translate}}</label>
      <br/>
      <label class="grey-24">{{this.consentDataService.getConsentdata().casenumber}}</label>
    </div>

    <br/>

    <div class="py-4 bg-white shadow ">
      <div class="row align-items-center ">
        <div class="col-2">
          <img class="img-left-margin" src="../../../assets/img/icon/Success_consent.svg">
        </div>
        <div class="col-7">
          <a (click)="onReadMore()"><label class="red-24-bold-user pointer">{{ 'user.consent' | translate }} </label></a>
        </div>
        <div class="col-3 column-align-right">
          <a (click)="onReadMore()"><img src="../../../assets/img/icon/arrow_right_22x10.svg"></a>
        </div>
      </div>
    </div>

    <br/>

    <div class="py-4 bg-white shadow ">
      <div class="row align-items-center ">
        <div class="col-2">
          <img class="img-left-margin" src="../../../assets/img/icon/sagsoplysninger.svg">
        </div>
        <div class="col-7">
          <a (click)="caseInfo()"><label class="red-24-bold-user pointer">{{ 'user.yourCase' | translate }} </label></a>
        </div>
        <div class="col-3 column-align-right">
          <a (click)="caseInfo()"><img src="../../../assets/img/icon/arrow_right_22x10.svg"></a>
        </div>
      </div>
    </div>

  </div>
</div>
