import {RequestStatusEnum} from './requestStatusEnum';

export class StatusModel {

  status: RequestStatusEnum;
  accepted: boolean;
  language: string;
  formattedCreatedDate: string;

  public clearData() {
    this.status = null;
    this.accepted = false;
    this.language = '';
    this.formattedCreatedDate = '';
  }
}
