<div class="d-flex flex-column content">

  <div *ngIf="isLoading">
    <div class="p-2 align-self-center">
      <mat-spinner [diameter]="60" class="mat-spinner-color"></mat-spinner>
    </div>
  </div>

  <div *ngIf="!isLoading">

    <div class="p-2 align-self-center">
      <h3>{{ 'status.description' | translate}}</h3>
    </div>

    <div class="p-2">
      <label class="red-20-bold">{{ 'status.p1' | translate }}</label>
      <label class="grey-20">{{ 'status.p1Text1' | translate }}</label>
      <label class="grey-20">{{ 'status.p1Text2' | translate }}</label>
    </div>

    <div>
      <div class="row p-3 mb-3 ">

        <div class="p-2">
          <label class="grey-light-20">{{ 'status.date' | translate }}</label><br>
          <label class="grey-24">{{acceptDate}}</label>
        </div>

        <div class="col-3 align-self-center ">
          <div>
            <img src="../../../assets/img/icon/Success_consent.svg">
          </div>
        </div>
      </div>
      <hr>
    </div>

    <div class="p-2 ">
      <label class="red-20-bold">{{ 'frontpage.readmoreHeader' | translate }}</label>
      <div class="row">
        <div class="col-11 ">
          <a (click)="onReadMore()"><label class="grey-20 pointer">{{ 'status.readmoreText' | translate }} </label></a>
        </div>
        <div class="col center">
          <a (click)="onReadMore()"><img src="../../../assets/img/icon/arrow_right_22x10.svg"></a>
        </div>
      </div>
    </div>

    <div class="p-2 ">
      <button #btn type="button" id="ok-btn" (click)="okButton(); "
              class="btn btn-danger btn-lg btn-block custom">{{ 'navigation.ok' | translate}}</button>
    </div>
  </div>
</div>
