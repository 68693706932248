<div class="d-flex flex-column content">

  <div class="p-2 align-self-center">
    <h3>{{'error.description'| translate}}</h3>
  </div>

  <div class="p-2 ">
    <h4 class="align-left">{{ errorDescription | translate}}</h4>
  </div>

</div>
