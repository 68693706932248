import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ConsentService} from '../../services/consent.service';
import {ConsentDataService} from '../../services/consent-data.service';
import {RequestStatusEnum} from '../../data/requestStatusEnum';
import {HttpbaseService} from '../../services/httpbase.service';
import {ErrorCodeEnum} from '../../data/errorCodeEnum';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-ac',
  templateUrl: './ac.component.html',
  styleUrls: ['./ac.component.scss']
})
export class AcComponent implements OnInit {
  private acceptedStatuses: RequestStatusEnum[] = [RequestStatusEnum.CONSENT_GIVEN, RequestStatusEnum.REQUEST_DONE,
    RequestStatusEnum.CONSENT_REPORTED];
  private id: string;
  consentAccepted: boolean;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private consentService: ConsentService,
              public consentDataService: ConsentDataService,
              private httpbaseService: HttpbaseService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getACData(this.id);
    this.translate.use('en');
  }

  onConsentStart() {
    this.router.navigate(['/overview/doctor', this.consentDataService.getAcData().doctorUUID]);
  }

  onAttorneyStart() {
    this.router.navigate(['/template']);
  }

  onRecourseStart() {
    this.router.navigate(['/template']);
  }

  getACData(id: string) {
    this.consentService.getACData(id).subscribe(value => {
      this.consentDataService.getAcData().status = value.status;
      this.consentDataService.getAcData().doctorUUID = value.doctorUUID;
      this.consentAccepted = this.acceptedStatuses.includes(this.consentDataService.getAcData().status);
    }, error => {
      this.httpbaseService.handleError(error);
      if (error.status === 404) {
        this.httpbaseService.setErrorCodeEnum(ErrorCodeEnum.AC_NOT_FOUND);
      } else {
        this.httpbaseService.setErrorCodeEnum(ErrorCodeEnum.TECHNICAL_ERROR);
      }
      this.router.navigate(['error']);
    });
  }
}
