
export class ConsentTextModel {

  consentText: string;
  version: string;
  language: string;
  createdTs: string;

  public clearData() {
    this.consentText = '';
    this.version = '';
    this.language = '';
    this.createdTs = '';
  }
}
