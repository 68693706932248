<div class="d-flex flex-column content">
  <div class="p-2 align-self-center">
    <h3>404 - page not found</h3>
  </div>
  <br/>
  <label class="grey-18">
    The page you are looking for has been removed or does not exist.
  </label>
  <br/>
  <label class="grey-18">
    Use the link that was sent to you to access this site.
  </label>
  <br/><br/>
  <div class="align-self-center">
    <label class="grey-20">
      <a href="https://www.sos.eu/">Go to SOS homepage</a>
    </label>
  </div>
</div>
