import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {FrontpageComponent} from './components/frontpage/frontpage.component';
import {StatusComponent} from './components/status/status.component';
import {HeaderComponent} from './components/shared/header/header.component';
import {ConsentComponent} from './components/consent/consent.component';
import {CaseComponent} from './components/case/case.component';
import {ErrorComponent} from './components/error/error.component';
import {DoctorComponent} from './components/doctor/doctor.component';
import {MarkdownModule} from 'ngx-markdown';
import {AcComponent} from './components/ac/ac.component';
import {TemplateComponent} from './components/template/template.component';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {EnvServiceProvider} from './services/env.service.provider';
import {RedirectComponent} from './components/redirect/redirect.component';
import {UserComponent} from './components/user/user.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule} from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    FrontpageComponent,
    StatusComponent,
    HeaderComponent,
    ConsentComponent,
    CaseComponent,
    ErrorComponent,
    DoctorComponent,
    AcComponent,
    TemplateComponent,
    RedirectComponent,
    UserComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    NgSelectModule,
    FormsModule,
    MarkdownModule.forRoot(),
    MatProgressSpinnerModule,
    BrowserAnimationsModule
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    EnvServiceProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
