<div class="d-flex flex-column content">

  <div class="p-2 align-self-center">
    <h3>{{ 'ac.description' | translate}}</h3>
  </div>

  <div class="container">
    <div class="row shadow p-3 mb-3 bg-white ">
      <div class="col-3 p-0 align-self-center">
        <div *ngIf="consentAccepted; else greyIcon">
          <img class="auto-cursor" src="../../../assets/img/icon/green_icon.png">
        </div>
        <ng-template #greyIcon>
          <img class="auto-cursor" src="../../../assets/img/icon/grey_icon.png">
        </ng-template>
      </div>
      <div class="col-8 p-0 align-self-center">
        <label class="red-24 font-weight-bold">{{ 'ac.consent' | translate}}</label>
      </div>
      <div class="col p-0 align-self-center">
        <div *ngIf="consentAccepted; else noLink">
          <a (click)="onConsentStart()">
            <img src="../../../assets/img/icon/arrow_right_22x10.svg">
          </a>
        </div>
        <ng-template #noLink>
          <img class="auto-cursor" src="../../../assets/img/icon/arrow_right_22x10.svg">
        </ng-template>
      </div>
    </div>
  </div>
</div>
