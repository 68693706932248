import {RequestStatusEnum} from './requestStatusEnum';

export class DoctorOverviewModel {

  company: string;
  casenumber: string;
  caseCreatedDate: string;
  language: string;
  uuid: string;
  consentStatus: RequestStatusEnum;
  consentText: string;
  acceptedDate: string;
  doctorUUID: string;

  constructor() {
  }

  public clearData() {
    this.company = '';
    this.casenumber = '';
    this.caseCreatedDate = '';
    this.language = '';
    this.uuid = '';
    this.consentStatus = null;
    this.doctorUUID = '';
    this.consentText = '';
    this.acceptedDate = '';
  }
}
