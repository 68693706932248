<div class="d-flex flex-column content">

  <div *ngIf="isLoading">
    <div class="p-2 align-self-center">
      <mat-spinner [diameter]="60" class="mat-spinner-color"></mat-spinner>
    </div>
  </div>

  <div *ngIf="!isLoading">

    <div class="p-2">
      <a (click)="backLink()">
        <label class="red-24-consent">{{ 'consent.back' | translate}}</label>
      </a>
    </div>

    <div class="p-2 align-self-center">
      <h3>{{ 'consent.description' | translate}}</h3>
    </div>

    <div class="p-2">
      <markdown [data]="consentText"></markdown>
    </div>

    <div class="p-2">
      <a (click)="backLink()">
        <label class="red-24-consent">{{ 'consent.back' | translate}}</label>
      </a>
    </div>

  </div>
</div>
