import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ConsentDataService} from '../../services/consent-data.service';
import {ErrorCodeEnum} from '../../data/errorCodeEnum';
import {ConsentService} from '../../services/consent.service';
import {HttpbaseService} from '../../services/httpbase.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {

  acceptDate: string;
  private uuid: string;
  isLoading: boolean;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private consentService: ConsentService,
              private httpbaseService: HttpbaseService,
              public consentDataService: ConsentDataService,
              private translate: TranslateService) {
    this.isLoading = true;
  }

  ngOnInit() {
    this.uuid = this.route.snapshot.paramMap.get('uuid');
    this.getConsentStatus(this.uuid);
  }

  getConsentStatus(uuid: string) {
    this.consentService.getConsentStatus(uuid).subscribe(value => {
      this.isLoading = false;
      this.consentDataService.getConsentStatus().accepted = value.accepted;
      this.consentDataService.getConsentStatus().formattedCreatedDate = value.formattedCreatedDate;
      this.consentDataService.getConsentStatus().status = value.status;
      this.consentDataService.getConsentStatus().language = value.language;
      this.translate.use(this.consentDataService.getConsentStatus().language);

      if (this.consentDataService.getConsentStatus().accepted) {
        this.acceptDate = this.consentDataService.getConsentStatus().formattedCreatedDate;
        this.router.navigate(['/user/' + this.uuid + '/status']);
      } else {
        this.router.navigate(['/user/' + this.uuid]);
      }
    }, error => {
      this.httpbaseService.handleError(error);
      if (error.status === 404) {
        this.httpbaseService.setErrorCodeEnum(ErrorCodeEnum.CONSENT_NOT_FOUND);
      } else {
        this.httpbaseService.setErrorCodeEnum(ErrorCodeEnum.TECHNICAL_ERROR);
      }
      this.router.navigate(['error']);
    });
  }

  okButton() {
    this.router.navigate(['/user/' + this.uuid + '/overview']);
  }

  onReadMore() {
    this.router.navigate(['/user/' + this.uuid + '/consent']);
  }
}
