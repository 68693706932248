<div class="d-flex flex-column content">

  <div class="p-2 align-self-center">
    <h3>{{ 'doctor.description' | translate}}</h3>
  </div>

  <div class="p-2 align-self-center">
    <ng-select [items]="languages"
               id="ng-select"
               (click)="onSelectLanguageEvent()"
               bindLabel="name"
               bindValue="code"
               [clearable]="false"
               hideSelected="true"
               [(ngModel)]="selectedLanguageCode">

      <ng-template ng-label-tmp let-item="item">
        <img class="ng-select-image-flag" [src]="item.img"/>
        <b>{{item.name}}</b>
      </ng-template>

      <ng-template ng-option-tmp let-item="item" let-index="index">
        <img class="ng-select-image-flag" [src]="item.img"/>
        <b>{{item.name}}</b>
      </ng-template>
    </ng-select>
  </div>

  <div class="p-2">
    <label class="grey-18">{{'doctor.number' | translate}}</label>
    <br/>
    <label class="grey-24">{{this.consentDataService.getDoctorData().casenumber}}</label>
  </div>

  <div class="p-2">
    <label class="grey-18">{{'doctor.date_created' | translate}}</label>
    <br/>
    <label class="grey-24">{{this.consentDataService.getDoctorData().caseCreatedDate}}</label>
  </div>

  <div class="p-2" *ngIf="consentAccepted; else notAccpeted">
    <label class="grey-18">{{'doctor.date_accpted' | translate}}</label>
    <br/>
    <label class="grey-24">{{this.consentDataService.getDoctorData().acceptedDate | date: 'dd-MM-yyyy'}}</label>
    <br/>
    <br/>

    <markdown [data]="consentText"></markdown>

  </div>

  <ng-template #notAccpeted>
    <div class="p-2">
      <label class="grey-18">{{'doctor.consent_not_given' | translate}}</label>
    </div>
  </ng-template>

</div>
