import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FrontpageComponent} from './components/frontpage/frontpage.component';
import {StatusComponent} from './components/status/status.component';
import {ConsentComponent} from './components/consent/consent.component';
import {CaseComponent} from './components/case/case.component';
import {ErrorComponent} from './components/error/error.component';
import {DoctorComponent} from './components/doctor/doctor.component';
import {AcComponent} from './components/ac/ac.component';
import {TemplateComponent} from './components/template/template.component';
import {RedirectComponent} from './components/redirect/redirect.component';
import {UserComponent} from './components/user/user.component';

const routes: Routes = [
  {path: 'user/:uuid', component: FrontpageComponent, data: {depth: 0}},
  {path: 'user/:uuid/status', component: StatusComponent, data: {depth: 10}},
  {path: 'user/:uuid/consent', component: ConsentComponent, data: {depth: 20}},
  {path: 'user/:uuid/overview', component: UserComponent, data: {depth: 25}},
  {path: 'user/:uuid/case', component: CaseComponent, data: {depth: 30}},
  {path: 'overview/doctor/:uuid', component: DoctorComponent, data: {depth: 50}},
  {path: 'overview/ac/:id', component: AcComponent, data: {depth: 60}},
  {path: 'template', component: TemplateComponent, data: {depth: 70}},
  {path: 'error', component: ErrorComponent},
  {path: '', component: RedirectComponent, data: {depth: 80}},
  {path: '#', component: RedirectComponent, data: {depth: 81}},
  {path: '**', component: RedirectComponent, data: {depth: 82}}

];

@NgModule({

  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
