import {Component, OnInit} from '@angular/core';
import {ConsentDataService} from '../../services/consent-data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-case',
  templateUrl: './case.component.html',
  styleUrls: ['./case.component.scss']
})
export class CaseComponent implements OnInit {

  private uuid: string;
  isLoading: boolean;

  constructor(private router: Router,
              private route: ActivatedRoute,
              public consentDataService: ConsentDataService,
              private location: Location) {
    this.isLoading = true;
  }

  ngOnInit(): void {
    this.uuid = this.route.snapshot.paramMap.get('uuid');
    if (this.consentDataService.getConsentdata().casenumber === undefined ||
      this.consentDataService.getConsentdata().caseCreatedDate === undefined ||
      this.consentDataService.getConsentdata().company === undefined) {
      this.router.navigate(['/user/' + this.uuid]);
    } else {
      this.isLoading = false;
    }
  }

  okButton() {
    this.location.back();
  }
}
