import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvService {

  public baseUrl = 'http://localhost:8103/api';
  public googleAPIKey = '';

  constructor() { }
}
