import {Component, OnInit} from '@angular/core';
import {ConsentDataService} from '../../../services/consent-data.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private router: Router,
              public consentDataService: ConsentDataService) {
  }

  ngOnInit(): void {
  }
}
