import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpbaseService} from './httpbase.service';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {ConsentModel} from '../data/consent.model';
import {AcoverviewModel} from '../data/acoverview.model';
import {EnvService} from './env.service';
import {StatusModel} from '../data/status.model';
import {DoctorOverviewModel} from '../data/doctorOverview.model';
import {ConsentTextModel} from '../data/consenttext.model';

@Injectable({
  providedIn: 'root'
})
export class ConsentService extends HttpbaseService {

  constructor(private httpClient: HttpClient,
              private router: Router,
              private env: EnvService) {
    super();
  }

  getConsentData(id: string): Observable<ConsentModel> {
    const requestUrl = `${this.env.baseUrl}/user/${id}`;
    console.log(`Find Consent with RequestURL: ${requestUrl}`);
    return this.httpClient.get<ConsentModel>(requestUrl);
  }

  getDoctorData(id: string): Observable<DoctorOverviewModel> {
    const requestUrl = `${this.env.baseUrl}/overview/doctor/${id}`;
    console.log(`Find Doctor with RequestURL: ${requestUrl}`);
    return this.httpClient.get<DoctorOverviewModel>(requestUrl);
  }

  getDoctorDataWithLanguage(id: string, language: string): Observable<DoctorOverviewModel> {
    const requestUrl = `${this.env.baseUrl}/overview/doctor/${id}/${language}`;
    console.log(`Find Doctor with RequestURL: ${requestUrl}`);
    return this.httpClient.get<DoctorOverviewModel>(requestUrl);
  }

  getACData(id: string): Observable<AcoverviewModel> {
    const requestUrl = `${this.env.baseUrl}/overview/ac/${id}`;
    console.log(`Travelcare view RequestURL: ${requestUrl}`);
    return this.httpClient.get<AcoverviewModel>(requestUrl);
  }

  putConsentData(id: string) {
    const requestUrl = `${this.env.baseUrl}/user/consent/` + id;
    console.log('Consent accepted with RequestURL: ${requestUrl}');
    return this.httpClient.put(requestUrl, null);
  }

  getConsentStatus(id: string): Observable<StatusModel> {
    const requestUrl = `${this.env.baseUrl}/user/${id}/status`;
    console.log(`Find Consent with RequestURL: ${requestUrl}`);
    return this.httpClient.get<StatusModel>(requestUrl);
  }

  getConsentText(id: string): Observable<ConsentTextModel> {
    const requestUrl = `${this.env.baseUrl}/user/${id}/consenttext`;
    console.log(`Find Consent text with RequestURL: ${requestUrl}`);
    return this.httpClient.get<ConsentTextModel>(requestUrl);
  }
}
