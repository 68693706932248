import {Injectable} from '@angular/core';
import {throwError} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorCodeEnum} from '../data/errorCodeEnum';

@Injectable({
  providedIn: 'root'
})
export class HttpbaseService {

  private errorCodeEnum: ErrorCodeEnum;

  constructor() {
    this.errorCodeEnum = null;
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

  public getErrorCodeEnum() {
    return this.errorCodeEnum;
  }

  public setErrorCodeEnum(errorCodeEnum: ErrorCodeEnum) {
    this.errorCodeEnum = errorCodeEnum;
  }
}
