<div class="d-flex flex-column content">

  <div *ngIf="isLoading">
    <div class="p-2 align-self-center">
      <mat-spinner [diameter]="60" class="mat-spinner-color"></mat-spinner>
    </div>
  </div>

  <div *ngIf="!isLoading">

    <div class="p-2 align-self-center">
      <h3>{{ 'frontpage.description' | translate}}</h3>
    </div>

    <div class="p-3 bg-white shadow">
      <label class="grey-20"> {{ 'frontpage.p1' | translate}}</label>
      <br/>
      <a (click)="onReadMore()"><label class="blue-20 pointer">{{ 'frontpage.readmoreText' | translate }} </label></a>
      <br/>
      <label class="grey-20"> {{ 'frontpage.consent' | translate}}</label>
      <br/><br/>
      <button #btn type="button" id="ok-btn" (click)="submitConsent(); btn.disabled=true; isLoading=true;"
              [disabled]="disableConsentButton"
              class="btn btn-danger btn-lg btn-block custom">{{ 'frontpage.ok' | translate}}</button>
    </div>
    <br/>

    <div class="p-3 bg-white shadow ">
      <label class="red-24-bold">{{ 'frontpage.yourCase' | translate }}</label>
      <div class="row">
        <div class="col-11 ">
          <a (click)="onCaseStart()">
            <label class="grey-20 pointer">{{ 'frontpage.case' | translate }} </label>
          </a>
        </div>
        <div class="col center">
          <a (click)="onCaseStart()">
            <img src="../../../assets/img/icon/arrow_right_22x10.svg" alt="">
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
