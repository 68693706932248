import {Component, OnInit} from '@angular/core';
import {HttpbaseService} from '../../services/httpbase.service';
import {ErrorCodeEnum} from '../../data/errorCodeEnum';
import language from '../../../assets/i18n/en.json';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  errorDescription: string;

  constructor(private httpbaseService: HttpbaseService) {
  }

  ngOnInit() {
    console.log('ErrorComponent is running');
    console.log('errorCodeEnum  ' + this.httpbaseService.getErrorCodeEnum());

    switch (this.httpbaseService.getErrorCodeEnum()) {
      case ErrorCodeEnum.TECHNICAL_ERROR:
        this.errorDescription = language.error.technical;
        break;
      case ErrorCodeEnum.CONSENT_NOT_FOUND:
        this.errorDescription = language.error.consent_404;
        break;
      case ErrorCodeEnum.DOCTOR_NOT_FOUND:
        this.errorDescription = language.error.doctor_404;
        break;
      case ErrorCodeEnum.AC_NOT_FOUND:
        this.errorDescription = language.error.ac_404;
        break;
      case ErrorCodeEnum.CONSENT_NOT_APPROVED:
        this.errorDescription = language.error.approve;
        break;
      default:
        this.errorDescription = language.error.default;
        break;
    }
  }
}
