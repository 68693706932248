import {Component, OnInit} from '@angular/core';
import {ConsentDataService} from '../../services/consent-data.service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {

  constructor(public consentDataService: ConsentDataService) {
    console.log('RedirectComponent constructor');
  }

  ngOnInit(): void {
  }
}
