import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ConsentService} from '../../services/consent.service';
import {ConsentDataService} from '../../services/consent-data.service';
import {HttpbaseService} from '../../services/httpbase.service';
import {TranslateService} from '@ngx-translate/core';
import {ErrorCodeEnum} from '../../data/errorCodeEnum';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  caseNumber: '';
  private uuid: string;
  isLoading: boolean;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private consentService: ConsentService,
              public consentDataService: ConsentDataService,
              private httpbaseService: HttpbaseService,
              private translate: TranslateService) {
    this.isLoading = true;
  }

  ngOnInit(): void {
    this.uuid = this.route.snapshot.paramMap.get('uuid');
    this.getConsent(this.uuid);
  }

  onReadMore() {
    this.router.navigate(['/user/' + this.uuid + '/consent']);
  }

  caseInfo() {
    this.router.navigate(['/user/' + this.uuid + '/case']);
  }

  getConsent(uuid: string) {
    this.consentService.getConsentData(uuid).subscribe(value => {
      this.isLoading = false;
      this.consentDataService.getConsentdata().casenumber = value.casenumber;
      this.consentDataService.getConsentdata().language = value.language;
      this.consentDataService.getConsentdata().company = value.company;
      this.consentDataService.getConsentdata().caseCreatedDate = value.caseCreatedDate;
      this.translate.use(this.consentDataService.getConsentdata().language);
    }, error => {
      this.httpbaseService.handleError(error);
      if (error.status === 404) {
        this.httpbaseService.setErrorCodeEnum(ErrorCodeEnum.AC_NOT_FOUND);
      } else {
        this.httpbaseService.setErrorCodeEnum(ErrorCodeEnum.TECHNICAL_ERROR);
      }
      this.router.navigate(['error']);
    });
  }
}
