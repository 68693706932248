<div class="d-flex flex-column content">

  <div *ngIf="isLoading">
    <div class="p-2 align-self-center">
      <mat-spinner [diameter]="60" class="mat-spinner-color"></mat-spinner>
    </div>
  </div>

  <div *ngIf="!isLoading">

    <div class="p-2 align-self-center">
      <h3>{{ 'case.description' | translate}}</h3>
    </div>

    <div class="p-2">
      <label class="grey-18">{{'case.number' | translate}}</label>
      <br/>
      <label class="grey-24">{{this.consentDataService.getConsentdata().casenumber}}</label>
    </div>

    <div class="p-2">
      <label class="grey-18">{{'case.date' | translate}}</label>
      <br/>
      <label class="grey-24">{{this.consentDataService.getConsentdata().caseCreatedDate}}</label>
    </div>

    <div class="p-2">
      <label class="grey-18">{{'case.insurance' | translate}}</label>
      <br/>
      <label class="grey-24">{{this.consentDataService.getConsentdata().company}}</label>
    </div>

    <div class="p-2 ">
      <button #btn type="button" id="ok-btn" (click)="okButton(); "
              class="btn btn-danger btn-lg btn-block custom">{{ 'navigation.ok' | translate}}</button>
    </div>
  </div>
</div>

