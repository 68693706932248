import {Injectable} from '@angular/core';
import {ConsentModel} from '../data/consent.model';
import {AcoverviewModel} from '../data/acoverview.model';
import {StatusModel} from '../data/status.model';
import {ConsentTextModel} from '../data/consenttext.model';
import {DoctorOverviewModel} from '../data/doctorOverview.model';

@Injectable({
  providedIn: 'root'
})
export class ConsentDataService {
  private consentdata: ConsentModel = new ConsentModel();
  private acData: AcoverviewModel = new AcoverviewModel();
  private consentStatus: StatusModel = new StatusModel();
  private consentText: ConsentTextModel = new ConsentTextModel();
  private doctordata: DoctorOverviewModel = new DoctorOverviewModel();

  constructor() {
  }

  getConsentdata(): ConsentModel {
    return this.consentdata;
  }

  getAcData(): AcoverviewModel {
    return this.acData;
  }

  getConsentStatus(): StatusModel {
    return this.consentStatus;
  }

  getConsentText(): ConsentTextModel {
    return this.consentText;
  }

  getDoctorData(): DoctorOverviewModel {
    return this.doctordata;
  }
}
