import {LanguageEnum} from './languageEnum';

export class Language {

  public static readonly LANGUAGES: Language[] = [
    new Language(LanguageEnum.DA, 'Dansk', '../assets/img/flag/da.svg'),
    new Language(LanguageEnum.NO, 'Norsk', '../../assets/img/flag/no.svg'),
    new Language(LanguageEnum.SV, 'Svenska', '/assets/img/flag/sv.svg'),
    new Language(LanguageEnum.FI, 'Suomi', '/assets/img/flag/fi.svg'),
    new Language(LanguageEnum.EN, 'English', '/assets/img/flag/en.svg')];

  code: LanguageEnum;
  name: string;
  img: string;

  constructor(code: LanguageEnum, name: string, img: string) {
    this.code = code;
    this.name = name;
    this.img = img;
  }
}
