import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ConsentService} from '../../services/consent.service';
import {ConsentDataService} from '../../services/consent-data.service';
import {RequestStatusEnum} from '../../data/requestStatusEnum';
import {HttpbaseService} from '../../services/httpbase.service';
import {ErrorCodeEnum} from '../../data/errorCodeEnum';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-frontpage',
  templateUrl: './frontpage.component.html',
  styleUrls: ['./frontpage.component.scss']
})
export class FrontpageComponent implements OnInit {

  disableConsentButton: boolean = false;
  private uuid: string;
  private language: string;
  private acceptedStatuses: RequestStatusEnum[] = [RequestStatusEnum.CONSENT_GIVEN, RequestStatusEnum.REQUEST_DONE,
    RequestStatusEnum.CONSENT_REPORTED];
  isLoading: boolean;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private consentService: ConsentService,
              public consentDataService: ConsentDataService,
              private httpbaseService: HttpbaseService,
              private translate: TranslateService) {
    this.isLoading = true;
  }

  ngOnInit() {
    this.uuid = this.route.snapshot.paramMap.get('uuid');
    this.getConsent(this.uuid);
  }

  getConsent(uuid: string) {
    this.consentService.getConsentData(uuid).subscribe(response => {
      this.isLoading = false;
      this.consentDataService.getConsentdata().company = response.company;
      this.consentDataService.getConsentdata().casenumber = response.casenumber;
      this.consentDataService.getConsentdata().caseCreatedDate = response.caseCreatedDate;
      this.consentDataService.getConsentdata().language = response.language;
      this.consentDataService.getConsentdata().uuid = response.uuid;
      this.consentDataService.getConsentdata().consentStatus = response.consentStatus;
      this.translate.use(this.consentDataService.getConsentdata().language);
      console.log("language in call " + this.language);
      if (this.acceptedStatuses.includes(this.consentDataService.getConsentdata().consentStatus)) {
        this.router.navigate(['/user/' + this.uuid + '/status']);
      }
    }, error => {
      this.httpbaseService.handleError(error);
      if (error.status === 404) {
        this.httpbaseService.setErrorCodeEnum(ErrorCodeEnum.AC_NOT_FOUND);
      } else {
        this.httpbaseService.setErrorCodeEnum(ErrorCodeEnum.TECHNICAL_ERROR);
      }
      this.router.navigate(['error']);
    });
  }

  submitConsent() {
    this.disableConsentButton = true;
    this.consentService.putConsentData(this.uuid).subscribe(value => {
    }, error => {
      this.httpbaseService.handleError(error);
      if (error.status === 404) {
        this.httpbaseService.setErrorCodeEnum(ErrorCodeEnum.CONSENT_NOT_FOUND);
      } else {
        this.httpbaseService.setErrorCodeEnum(ErrorCodeEnum.TECHNICAL_ERROR);
      }
      this.router.navigate(['error']);
    });
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate(['/user', this.uuid]);
      console.log('Refreshing the page');
    });
  }

  onCaseStart() {
    this.router.navigate(['/user/' + this.uuid + '/case']);
  }

  onReadMore() {
    this.router.navigate(['/user/' + this.uuid + '/consent']);
  }
}
